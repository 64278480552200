<template>
  <div class="dishes">
    <dish-filter @create-dish="openModal" />
    <dish-table />
    <dish-modal :show.sync="showModal" />
  </div>
</template>

<script>
import DishTable from './dish-table/dish-table.vue';
import DishModal from './dish-modal/dish-modal.vue';
import DishFilter from './dish-filter/dish-filter.vue';

export default {
  name: 'Dishes',
  components: { DishTable, DishModal, DishFilter },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
  },
};
</script>
