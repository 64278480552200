<template>
  <el-dialog :title="title" :visible.sync="visible" :fullscreen="true" :before-close="closeModal">
    <el-form
      ref="dish"
      :model="dish"
      label-position="top"
      :rules="formRules"
      @submit.prevent.native="saveFn"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Название" prop="title">
            <el-input v-model="dish.title" placeholder="Пюре с котлетой" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Штрихкод" prop="barcode">
            <el-input v-model="dish.barcode" placeholder="12512511" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Цех" prop="workshop">
            <el-input v-model="dish.workshop" placeholder="Производственный" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Налог" prop="tax">
            <el-input v-model="dish.tax" placeholder="200.00" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Категория приема пищи" prop="meal_id">
            <el-select
              v-if="categories"
              v-model="dish.meal_id"
              placeholder="Выберите категорию"
              style="width: 100%"
            >
              <el-option
                v-for="category in categories.data"
                :key="category.id"
                :label="category.title"
                :value="category.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Время приготовления" prop="cooking_time">
            <el-time-select
              v-model="dish.cooking_time"
              class="time-sel"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="Выберите время приготовления"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Загрузить картинку" prop="image">
            <el-upload :http-request="uploadFile" action="" :file-list="fileList">
              <el-button size="small" type="primary"> Нажмите, чтобы загрузить </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="ml-3" label="Цвет" prop="color">
            <el-color-picker v-model="dish.color" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Процесс приготовления" prop="cooking_process">
        <el-input v-model="dish.cooking_process" type="textarea" placeholder="Опишите процесс" />
      </el-form-item>
      <el-form-item class="mt-5 is-flex is-justify-content-flex-end">
        <el-button @click="visible = false"> Закрыть </el-button>
        <el-button type="primary" :loading="loading" @click="saveFn">
          {{ dish.id ? 'Сохранить' : 'Добавить' }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';
import { LOADING, ERROR } from '../shared/state/types/getter_types';
import { FETCH_DISHES, STORE_DISH } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'DishModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить тех карту блюда',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      dish: {
        title: '',
        barcode: '',
        workshop: '',
        tax: '',
        cooking_process: '',
        cooking_time: '',
        category_id: '',
        image: '',
        color: '',
        calories: 0,
        proteins: 0,
        fats: 0,
        carbohydrates: 0,
      },
      fileList: [],
      formRules: {
        title: [{ required: true, message: 'Введите название', trigger: 'blur' }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapState({ categories: (state) => state.mealsState.mealsData.data }),
    ...mapGetters({ loading: LOADING, error: ERROR }),
  },
  async created() {
    await this.fetchMeals();
  },
  methods: {
    ...mapActions({
      fetch: FETCH_DISHES,
      fetchMeals: FETCH_MEALS,
      store: STORE_DISH,
    }),
    selectMeal(item) {
      this.dish.meal_id = item.id;
    },
    async closeModal() {
      this.visible = false;
      await this.fetch();
    },
    async saveFn() {
      this.$refs.dish.validate(async (valid) => {
        if (valid) {
          await this.store(this.dish);
          if (this.error) {
            this.showErrorMessage('Ошибка добавления');
          }
          this.showSuccessMessage('Успешно добавлен');
          this.closeModal();
          return true;
        }
        return false;
      });
    },
    async uploadFile(e) {
      const { file } = e;
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.$store.dispatch('UPLOAD_FILE', formData);
      this.dish.image = `${process.env.VUE_APP_API_HOST_IMG}${response.path}`;
    },
  },
};
</script>
