<template>
  <div class="dishes-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="isAvailable ? tableRules : tableRulesAdmin"
      @reload="changePage"
      @edit="handleEdit"
      @copy="handleCopy"
      @delete="handleDelete"
      @print="handlePrint"
    />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import {
  SELECT_DISH,
  DELETE_DISH,
  FETCH_DISHES,
  COPY_DISH,
  PRINT_DISH,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'DishTable',
  components: { AppTable },
  mixins: [notifyMixin],
  data() {
    return {
      page: 1,
      query: {},
      fields: [
        { label: '#', value: 'id', width: 100 },
        { label: 'Название', value: 'title', width: 350 },
        { label: 'Категория', value: 'meal.title' },
        { label: 'Вес', value: 'netto' },
      ],
      tableRules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'copy',
          type: 'info',
          icon: 'el-icon-document-copy',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      tableRulesAdmin: [
        {
          value: 'edit',
          type: 'Info',
          icon: 'el-icon-folder-opened',
        },
        {
          value: 'print',
          type: 'info',
          icon: 'el-icon-printer',
        },
      ],
      modal: {
        show: false,
        title: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  watch: {
    async page(page) {
      await this.fetch({ page, filter: this.$route.query });
    },
    async $route(to) {
      await this.fetch({ page: this.page, filter: to.query });
    },
  },
  async created() {
    const queryParams = this.$route.query;
    if (queryParams.title) {
      this.query = { ...this.query, title: queryParams.title };
    }
    if (queryParams['meal.title']) {
      this.query = { ...this.query, 'meal.title': queryParams['meal.title'] };
    }
    await this.fetch({ page: this.page, filter: this.query });
  },
  methods: {
    ...mapActions({
      select: SELECT_DISH,
      delete: DELETE_DISH,
      fetch: FETCH_DISHES,
      copy: COPY_DISH,
    }),
    changePage(page) {
      this.page = page;
    },
    handlePrint({ id }) {
      this.$store.dispatch(PRINT_DISH, id);
    },
    async handleEdit(dish) {
      await this.select(dish.id);
      this.$router.push(`/settings/dish/${dish.id}`);
    },
    handleDelete(dish) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(dish.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Тех карта успешно удалена');
        })
        .catch(() => {});
    },
    async handleCopy(item) {
      await this.copy({ id: item.id, options: { page: this.page } });
    },
  },
};
</script>
