<template>
  <div class="guide-filter">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-badge is-dot :hidden="isActiveFilter" class="item">
          <el-button icon="el-icon-set-up" @click="openFilter"> Фильтр </el-button>
        </el-badge>
      </el-col>
      <el-col v-if="isAvailable" :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="primary" icon="el-icon-plus" @click="$emit('create-dish')">
            Добавить блюдо
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div v-show="isShowFilter" class="filter-panel mt-4">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input v-model="title" prefix-icon="el-icon-search" placeholder="Поиск по названию" />
        </el-col>
        <el-col :span="8">
          <el-autocomplete
            v-model="category"
            style="width: 100%"
            :fetch-suggestions="searchMealsFn"
            :debounce="600"
            prefix-icon="el-icon-search"
            placeholder="Поиск по категории"
            value-key="title"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';

export default {
  name: 'DishFilter',
  data() {
    return {
      isShowFilter: false,
      isActiveFilter: true,
      category: null,
      title: null,
      showModal: false,
      query: {},
    };
  },
  computed: {
    ...mapState({
      meals: (state) => state.mealsState.mealsData.data,
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
    path() {
      return this.$route.path;
    },
  },
  watch: {
    query(query) {
      if (query.title === '') {
        delete query.title;
      }
      if (query['meal.title'] === '') {
        delete query['meal.title'];
      }
      this.$router.push({ path: this.path, query }).catch(() => {});
    },
    title(value) {
      this.query = { ...this.query, title: value };
    },
    category(value) {
      this.query = { ...this.query, 'meal.title': value };
    },
  },
  created() {
    const queryParams = this.$route.query;
    if (queryParams.title) {
      this.title = queryParams.title;
      this.openFilter();
    }
    if (queryParams['meal.title']) {
      this.category = queryParams['meal.title'];
      this.openFilter();
    }
  },

  methods: {
    ...mapActions({ fetchMeals: FETCH_MEALS }),
    openFilter() {
      this.isShowFilter = !this.isShowFilter;
      this.isActiveFilter = !this.isActiveFilter;
    },
    async searchMealsFn(queryString, cb) {
      const filter = queryString ? { filter: { title: queryString } } : {};
      await this.fetchMeals(filter);
      cb(this.meals.data);
    },
  },
};
</script>
